import {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import WizardConfiguration from '../../../tmp/WizardConfiguration';
import styles from './AddService.module.less';
import {
    Typography,
    Button,
    Row,
    Col,
    Form,
    message,
    Tooltip,
    Modal,
    notification,
    Spin,
    Radio,
} from 'antd';
import DatabaseVendor from '../../../types/DatabaseVendor';
import CcxComponentProps from '../../../core/CcxComponent';
import { CDN_URL, hideCloudProvider } from '../../../core/CcxEnv';
import CloudProvider from '../../../types/CloudProvider';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import InstanceSize from '../../../types/InstanceSize';
import User from '../../../types/User';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import AddServiceDatabaseVendorStep from './AddServiceDatabaseVendorStep';
import AddServiceCreateDatastoreStep from './AddServiceCreateDatastoreStep';
import { WizardSelectTypeStepIcon } from '../../../tmp/WizardSelectTypeStep';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import { useHistory, useParams } from 'react-router-dom';
import useVpcs from '../../../core/hooks/useVpcs';
import Vpc from '../../../types/Vpc';
import useContentCidrs from '../../../core/hooks/useContentCidrs';
import { InfoCircleOutlined } from '@ant-design/icons';
import AppForm from '../../ccx/common/AppForm';
import VpcService from '../../../services/VpcService';
import CcxIconCheckCircleTwoTone from '../../ccx/icons/CcxIconCheckCircleTwoTone';
import { VpcsContext } from '../../../core/context/VpcsContext';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import AppLogoBrandMardiaDb from '../../ccx/common/AppLogoBrandMardiaDb';
import AppLogoBrandPostgreSql from '../../ccx/common/AppLogoBrandPostgreSql';
import AppLogoBrandPercona from '../../ccx/common/AppLogoBrandPercona';
import AppLogoBrandRedis from '../../ccx/common/AppLogoBrandRedis';
import AddServiceCreateVPCStep from './AddServiceCreateVPCStep';
import Address from '../../../types/Address';
import BillingUserData from '../../../types/BillingUserData';
import BillingService from '../../../services/BillingService';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import { FormInstance } from 'antd/lib/form';
import SetupIntent from '../../../types/SetupIntent';
import { usePageTracking } from '../../../core/hooks/usePageTracking';
import AppLogoBrandMssql from '../../ccx/common/AppLogoBrandMssql';
import { isOnlyPublicNetworkEnabled } from './WizardFormConfigurationStep4';
import { getCloudProviderLogo } from '../../projects/dataStores/DataStoreCloudProviderLogo';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { excludeFakeAddresses } from '../../../core/helpers';
import { setInitialState } from '../../../slices/restore.slice';

interface Props extends CcxComponentProps {
    user?: User;
}

interface UrlProps {
    service: 'vpc' | 'datastore';
}

export default function AddService({
    testId = 'AddService',
    user,
}: Props): ReactElement {
    usePageTracking();

    const { Text } = Typography;

    const { service } = useParams<UrlProps>();

    const { deploymentOptions, isLoading: loading } = useAppSelector(
        (state) => state.deploymentOptions
    );
    const [dbVendors, setDbVendors] = useState<any[]>();

    const [form] = Form.useForm();
    const [vpcForm] = Form.useForm();
    const [vpcWizardForm] = Form.useForm();
    const vpcContext = useContext(VpcsContext);

    const [step, setStep] = useState<string>(
        service === 'vpc' ? 'createVPC' : 'databaseVendor'
    );
    const [actionType] = useState<string>('datastore');
    const [selectedTechType, setSelectedTechType] = useState<string>();
    const [selectedTech, setSelectedTech] = useState<DatabaseVendor>();
    const [databaseVendorType, setDatabaseVendorType] = useState<string>('');
    const [cloudProvider, setCloudProvider] = useState<CloudProvider>();
    const [selectedCloudProvider, setSelectedCloudProvider] =
        useState<CloudProvider>();
    const [regions, setRegions] = useState<CloudProviderRegion[]>();
    const [region, setRegion] = useState<CloudProviderRegion>();
    const [instance, setInstance] = useState<InstanceSize>();
    const [volumeType, setVolumeType] = useState<InstanceVolumeType>();
    const [network, setNetwork] = useState<any>(undefined);
    const [networkType, setNetworkType] = useState<any>(undefined);
    const [numberOfNodes, setNumberOfNodes] = useState(1);
    const [volumeSize, setVolumeSize] = useState<
        InstanceVolumeSize | undefined
    >(undefined);
    const [volumeIops, setVolumeIops] = useState<
        InstanceVolumeIopsValue | undefined
    >(undefined);
    const [monthlyCost, setMonthlyCost] = useState(0);
    const [version, setVersion] = useState<string>();

    const { vpcs, refresh: refreshVpcs } = useVpcs(
        cloudProvider?.code,
        region?.code
    );

    const [vpc, setVpc] = useState<Vpc | undefined>(undefined);

    const [availabilityZones, setAvailabilityZones] = useState<any>(undefined);
    const [zones, setZones] = useState<any>(undefined);

    const [vpcModal, setVpcModal] = useState(false);

    const [enteredVolumeSize, setEnteredVolumeSize] = useState<
        number | undefined
    >(undefined);

    const [enteredIOPS, setEnteredIOPS] = useState<number | undefined>(
        undefined
    );

    const { cidrs } = useContentCidrs();
    const dispatch = useAppDispatch();
    const getLogo = (v: DatabaseVendor, isString = true): any => {
        if (isString) {
            if (v.isMariadb())
                return `${CDN_URL}vendor-mariadb-brand-logo-short.svg`;
            if (v.isPostgreSql())
                return `${CDN_URL}vendor-postgresql-brand-logo-short.svg`;
            if (v.isPercona())
                return `${CDN_URL}vendor-mysql-brand-logo-short.svg`;
            if (v.isRedis())
                return `${CDN_URL}vendor-redis-brand-logo-short.svg`;
            if (v.isMssql()) return `${CDN_URL}vendor-mssql-brand-logo.svg`;
            return '';
        } else {
            if (v.isMariadb())
                return <AppLogoBrandMardiaDb useExtended={false} width={40} />;
            if (v.isPostgreSql())
                return (
                    <AppLogoBrandPostgreSql useExtended={false} width={40} />
                );
            if (v.isPercona())
                return <AppLogoBrandPercona useExtended={false} width={40} />;
            if (v.isRedis())
                return <AppLogoBrandRedis useExtended={false} width={40} />;
            if (v.isMssql())
                return <AppLogoBrandMssql useExtended={false} width={40} />;
            return null;
        }
    };

    const fieldsSetup = {
        step1: [
            {
                name: ['vpcName'],
                testId: 'CreateVpcButtonVpcName',
                required: true,
                label: 'Name',
                placeholder: 'Name your VPC',
                type: 'input',
            },
            {
                name: ['cidrIpv4Block'],
                testId: 'CreateVpcButtonCidrIpv4Block',
                required: true,
                label: (
                    <>
                        IPv4 CIDR block
                        <Tooltip title="Available CIDR blocks on the selected Region.">
                            <InfoCircleOutlined
                                className={styles.AddServiceModalCIDRIcon}
                            />
                        </Tooltip>
                    </>
                ),
                validationName: 'IPv4 CIDR block',
                placeholder: 'Select a CIDR block from the list',
                type: 'select',
                options: [],
            },
        ],
    };

    const [fields, setFields] = useState<any>(fieldsSetup);
    const [errorFields, setErrorFields] = useState([]);

    const [subscriptionUser, setSubscriptionUser] = useState<boolean>(false);
    const [notificationEmails, setNotificationEmails] = useState<
        string[] | undefined
    >(undefined);
    const [intentResponse, setIntentResponse] = useState<SetupIntent>();

    const history = useHistory();

    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );

    useEffect(() => {
        return () => {
            dispatch(setInitialState());
        };
    }, []);

    useEffect(() => {
        const instanceMonthlyPrice = instance?.getMonthlyPrice() || 0;
        const instanceNumber = numberOfNodes || 0;
        const storageMonthlyPrice = volumeType?.getMonthlyPrice() || 0;
        const numberOfGB = enteredVolumeSize || 0;
        const iopsMonthlyPrice = volumeType?.getMonthlyIOPSPrice() || 0;
        const numberOfIOPS = enteredIOPS || 0;

        const monthlyPriceEstimate =
            instanceMonthlyPrice * instanceNumber +
            storageMonthlyPrice * instanceNumber * numberOfGB +
            iopsMonthlyPrice * instanceNumber * numberOfIOPS;

        setMonthlyCost(parseFloat(monthlyPriceEstimate.toFixed(2)));
    }, [instance, numberOfNodes, volumeType, enteredVolumeSize, enteredIOPS]);

    useEffect(() => {
        if (fields && cloudProvider && region) {
            const newFields = fields;
            setFields(newFields);
        }
    }, [cloudProvider, fields, region]);

    useEffect(() => {
        if (fields && cidrs && cidrs.length > 0) {
            const newStep1 = fields.step1.map((f: any) => {
                if (f.name && f.name[0] === 'cidrIpv4Block') {
                    f.options =
                        cidrs?.map((c: string) => {
                            return { label: c, value: c };
                        }) || [];
                }

                return f;
            });
            const newFields = fields;
            newFields.step1 = newStep1;
            setFields(newFields);
        }
    }, [cidrs, fields]);

    const networks = useMemo(() => {
        if (cloudProvider && deploymentOptions) {
            return deploymentOptions?.getNetworks(cloudProvider);
        }
        return undefined;
    }, [cloudProvider, deploymentOptions]);

    useEffect(() => {
        if (
            deploymentOptions?.getDatabaseVendors() &&
            deploymentOptions?.getDatabaseVendors().length > 0
        ) {
            setDbVendors(
                deploymentOptions
                    ?.getDatabaseVendors()
                    .map((v: DatabaseVendor) => {
                        const icon: WizardSelectTypeStepIcon = {
                            name: v.name,
                            src: getLogo(v),
                        };

                        return {
                            name: v.name,
                            key: v.code,
                            icon,
                            value: '',
                            beta: v.beta,
                            description: v.info,
                            documentationLink: '',
                            extensions:
                                v.extensions.length > 0 ? (
                                    <div>
                                        The following extensions are supported:
                                        <ul>
                                            {v.extensions.map((e: string) => (
                                                <li key={e}>{e}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <></>
                                ),
                            extra:
                                v.versions && v.versions.length > 0 ? (
                                    <div key={`db-version-${v.name}`}>
                                        <Text type="danger">*</Text> Version:{' '}
                                        {getVersionButtons(v)}
                                    </div>
                                ) : null,
                        };
                    })
            );
        }
    }, [deploymentOptions]);

    useEffect(() => {
        if (selectedCloudProvider) {
            setRegions(selectedCloudProvider.regions);
            setInstance(undefined);
            setVolumeType(undefined);
            setVolumeSize(undefined);

            const networks = deploymentOptions?.getNetworks(
                selectedCloudProvider
            );
            let network = undefined;
            if (networks && isOnlyPublicNetworkEnabled(networks)) {
                network = networks.find((n: any) => n.code === 'public');
            }

            form.setFieldsValue({
                instanceSize: undefined,
                volumeType: undefined,
                volumeSize: undefined,
                network,
            });

            setDefaultRegion();
        }
    }, [selectedCloudProvider]);

    useEffect(() => {
        if (cloudProvider && region) {
            setAvailabilityZones(
                deploymentOptions?.getAvailabilityZones(cloudProvider, region)
            );
        }
    }, [cloudProvider, region]);

    useEffect(() => {
        form.setFieldsValue({
            databaseVendor: selectedTech,
            databaseVendorType: undefined,
        });
        setDatabaseVendorType('');
    }, [selectedTech]);

    useEffect(() => {
        if (user) {
            const emails = excludeFakeAddresses([user?.login]);

            form.setFieldsValue({
                notificationRecipients: emails,
            });

            setNotificationEmails(emails);
        }
    }, [form, user]);

    useEffect(() => {
        const vs =
            cloudProvider &&
            volumeType &&
            deploymentOptions?.getVolumeSize(cloudProvider, volumeType);

        // Don't set volume size in case of redis. That will be computed dynamically on backend
        if (selectedTech?.code !== 'redis') {
            if (
                !form.getFieldValue('volumeSize') ||
                (vs &&
                    (form.getFieldValue('volumeSize') < vs?.min ||
                        form.getFieldValue('volumeSize') > vs?.max))
            ) {
                form.setFieldsValue({
                    volumeSize: vs?.default,
                });
                setVolumeSize(vs);
            }

            setVolumeSize(vs);
        }
    }, [cloudProvider, volumeType]);

    useEffect(() => {
        if (vpcs && vpcs.length === 1 && network?.code === 'private') {
            setVpc(vpcs[0]);
            form.setFieldsValue({ vpcUuid: vpcs[0]?.id });
        } else {
            setVpc(undefined);
            form.setFieldsValue({ vpcUuid: undefined });
        }
    }, [vpcs]);

    const handleVolumeIopsChange = (
        value: InstanceVolumeIopsValue | undefined
    ) => {
        setVolumeIops(value);
    };

    const handleOnValuesChange = () => {
        const network = networks?.find(
            (item) => item.code === form.getFieldValue('networkCode')
        );

        setNumberOfNodes(form.getFieldValue('numberOfNodes'));
        setNetwork(network);
        setSelectedTech(form.getFieldValue('databaseVendor'));
        setDatabaseVendorType(form.getFieldValue('databaseVendorType'));
        setVolumeType(form.getFieldValue('volumeType'));
        setNetworkType(form.getFieldValue('networkType'));

        const cp = form.getFieldValue('cloudProvider');
        if (cp) {
            // check if the selected provider has only 1 volumeType
            if (deploymentOptions?.getVolumeTypes(cp)?.length === 1) {
                form.setFieldsValue({
                    volumeType: deploymentOptions.getVolumeTypes(cp)[0],
                });
            }
        }

        if (
            form.getFieldValue('cloudProvider') &&
            cloudProvider &&
            region &&
            form.getFieldValue('volumeType')
        ) {
            const vs = deploymentOptions?.getVolumeSize(
                cloudProvider,
                form.getFieldValue('volumeType')
            );

            const vi = deploymentOptions?.getVolumeIops(
                cloudProvider,
                form.getFieldValue('volumeType')
            );

            // Don't set volume size in case of redis. That will be computed dynamically on backend
            if (selectedTech?.code !== 'redis') {
                if (!form.getFieldValue('volumeSize')) {
                    form.setFieldsValue({
                        volumeSize: vs?.default,
                    });
                    setVolumeSize(vs);
                }
            }

            if (!form.getFieldValue('volumeIops')) {
                form.setFieldsValue({
                    volumeIops: vi?.default,
                });
            }
            setVolumeSize(vs);
            handleVolumeIopsChange(vi);
        }

        if (
            form.getFieldValue('cloudProvider') &&
            cloudProvider &&
            region &&
            form.getFieldValue('cloudRegion')
        ) {
            setAvailabilityZones(
                deploymentOptions?.getAvailabilityZones(
                    cloudProvider,
                    form.getFieldValue('cloudRegion')
                )
            );
        }
        // reset vpc, networkType, and availabilityZones formvalues when network is set to 'public'
        if (network?.code === 'public') {
            form.setFieldsValue({
                vpcUuid: undefined,
                networkType: undefined,
                availabilityZones: undefined,
            });
            setNetworkType(undefined);
            setZones(undefined);
            setVpc(undefined);
        } else if (
            network?.code === 'private' &&
            !form.getFieldValue('networkType')
        ) {
            form.setFieldsValue({
                networkType: network?.ha_available[numberOfNodes]
                    ? undefined
                    : 'regular',
            });
            setNetworkType(
                network?.ha_available[numberOfNodes] ? undefined : 'regular'
            );
            if (
                !form.getFieldValue('availabilityZones') &&
                availabilityZones?.length === 1
            ) {
                handleAvailabilityZoneChange?.(0, availabilityZones[0]?.code);
            }
            if (!form.getFieldValue('vpcUuid') && vpcs?.length === 1) {
                handleVpcChange?.(vpcs[0]?.id);
            }
        }

        if (form.getFieldValue('instanceSize')) {
            setInstance(form.getFieldValue('instanceSize'));
        }
    };

    const showCloudProviderStep = (): boolean => {
        return !(
            hideCloudProvider ||
            deploymentOptions?.hasOnlyOneCloudProviderAndRegion()
        );
    };

    const handleTechTypeSelect = (type: string) => {
        if (!showCloudProviderStep()) {
            setDefaultCloudProvider();
        }
        setSelectedTechType(type);
        setSelectedTech(deploymentOptions?.getDatabaseVendorByCode(type));
    };

    const handleTechTypeContinueClick = () => {
        setStep('createDatastore');
    };

    const setDefaultCloudProvider = () => {
        const defaultCloudProvider =
            deploymentOptions?.getDefaultCloudProviders();
        setCloudProvider(defaultCloudProvider);
        form.setFieldsValue({
            cloudProvider: defaultCloudProvider,
        });
    };

    const setDefaultRegion = () => {
        const defaultRegion = cloudProvider?.getDefaultRegion();
        if (!showCloudProviderStep()) {
            setRegion(defaultRegion);
            form.setFieldsValue({
                region: defaultRegion,
            });
        } else if (!isBackupRestore) {
            setRegion(undefined);
            form.setFieldsValue({
                region: undefined,
            });
        }
    };

    const handleBackClick = () => {
        history.push({
            pathname: '/',
        });
        dispatch(setInitialState());
    };

    const handleDeselect = (a: any) => {
        message.info(`Tag "${a}" was removed`);
    };

    const handleTagChange = (values: any) => {
        const tags = values
            ?.map((tag: string) => tag.trim())
            .filter((tag: string) => tag !== '');
        const uniqueTags = Array.from(new Set(tags));

        if (form) {
            form.setFieldsValue({
                tags: uniqueTags,
            });
        }

        if (values.length > 12) {
            values.pop();
            message.warning('You may only add up to 12 tags');
        }
    };

    const handleVendorTypeChange = (e: any) => {
        setDatabaseVendorType(e.target.value);
        form.setFieldsValue({
            numberOfNodes: selectedTech?.getSelectedNode(e.target.value),
        });
    };

    const handleCloudProviderChange = (e: any) => {
        if (!(cloudProvider && cloudProvider.logo === e.target.value.logo)) {
            setCloudProvider(e.target.value);
            form.setFieldValue('cloudProvider', e.target.value);
            setSelectedCloudProvider(e.target.value);
            setRegion(undefined);
        }
    };

    const handleInstanceSizeChange = (e: any) => {
        setInstance(e.target.value);
    };

    const handleVersionChange = (e: any) => {
        setVersion(e.target.value);
        form.setFieldsValue({
            version: e.target.value,
        });
    };

    const getVersionButtons = useCallback(
        (vendor: DatabaseVendor) => {
            return (
                vendor.versions &&
                vendor.versions.length > 0 && (
                    <Radio.Group
                        data-testid={`${testId}Versions`}
                        onChange={handleVersionChange}
                        className={
                            vendor.versions.length === 1
                                ? styles.AddServiceVersion
                                : styles.AddServiceVersions
                        }
                        defaultValue={vendor.version}
                    >
                        {vendor.versions.map((versionOpt: string) => (
                            <Radio.Button
                                data-testid={`${testId}VersionsRadio${versionOpt}`}
                                value={versionOpt}
                                key={versionOpt}
                            >
                                <span>{versionOpt}</span>
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                )
            );
        },
        [version, handleVersionChange, testId]
    );

    useEffect(() => {
        setVersion(selectedTech?.version);

        form.setFieldsValue({
            version: selectedTech?.version,
        });
    }, [selectedTechType]);

    const handleVolumeTypeChange = (e: any) => {
        setVolumeType(e.target.value);
    };

    const handleNetworkChange = (e: any) => {
        const network = networks?.find((item) => item.code === e.target.value);
        setNetwork(network);
        form.setFieldsValue({
            network,
        });
    };

    const handleNetworkTypeChange = (e: any) => {
        setNetworkType(e.target.value);
        form.setFieldsValue({
            availabilityZones: undefined,
        });
    };

    const handleVpcChange = (vpcUuid: string) => {
        if (vpcUuid === 'new') {
            setVpcModal(true);
            return;
        }
        const vpc = vpcs?.find((v: Vpc) => v.id === vpcUuid);
        form.setFieldsValue({ vpcUuid: vpc?.id });
        setVpc(vpc);
    };

    const handleAvailabilityZoneChange = (instance: number, value: any) => {
        const azs = form.getFieldValue('availabilityZones') || [];
        azs[instance] = value;
        form.setFieldsValue({
            availabilityZones: azs,
        });
        setZones(azs);
    };

    const handleNumberOfNodesChange = (e: any) => {
        setNumberOfNodes(e.target.value);
    };

    const saveEmailNotifications = (emails: string[]) => {
        setNotificationEmails(emails);
    };

    const handleRegionChange = (e: any) => {
        const tempCloudProviders = deploymentOptions?.cloud.cloudProviders;
        let selectedProvider: CloudProvider | null = null;
        tempCloudProviders?.forEach((provider: CloudProvider) => {
            const isInList = provider.regions.find((r: CloudProviderRegion) => {
                return r.code === e.target.value.code;
            });
            if (isInList) {
                selectedProvider = provider;
            }
        });
        if (selectedProvider) {
            setCloudProvider(selectedProvider);
            form.setFieldValue('cloudProvider', selectedProvider);
            setRegion(e.target.value);
        }
    };

    const handleCancel = () => {
        setVpcModal(false);
        vpcForm.resetFields();
        setErrorFields([]);
    };

    const validateForm = async (form: FormInstance) => {
        try {
            const a = await form.validateFields();
            return true;
        } catch (e: any) {
            setErrorFields(e.errorFields);
            message.error(
                'One or more fields have errors. Please double check and try again.'
            );
            return false;
        }
    };

    const onSubmitButtonClick = async () => {
        const stepValid = await validateForm(vpcForm);

        if (stepValid) {
            try {
                cloudProvider &&
                    region &&
                    (await doApiRequest(
                        vpcForm.getFieldsValue(),
                        cloudProvider,
                        region
                    ));
                handleCancel();
            } catch (e) {
                console.log('error e=', e);
                message.error('There was an error.' + e);
            }
        }
    };

    const doApiRequest = async (
        { cidrIpv4Block, vpcName }: any,
        cloudProvider: CloudProvider,
        region: CloudProviderRegion
    ) => {
        try {
            await VpcService.createVpc({
                cloud: cloudProvider.code,
                region: region.code,
                cidrIpv4Block,
                vpcName,
            });

            notification.open({
                message: 'Create VPC',
                description: `New VPC successfully created!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            vpcContext.refresh && (await vpcContext.refresh());

            refreshVpcs();

            return true;
        } catch (e) {
            notification.open({
                message: 'Create VPC',
                description: `There was an error creating your VPC. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);
        }
    };

    const createUserSubscription = async (
        billingForm: any,
        paymentMethod: any
    ) => {
        const data = {
            address: new Address({
                country: billingForm.getFieldValue('country'),
                state: billingForm.getFieldValue('state'),
                city: billingForm.getFieldValue('city'),
                line_1: billingForm.getFieldValue('line1'),
                line_2: billingForm.getFieldValue('line2'),
                postal_code: billingForm.getFieldValue('postalCode'),
            }),
            paymentMethod,
            coupon: form.getFieldValue('coupon'),
            userData: new BillingUserData({
                company_name: billingForm.getFieldValue('companyName'),
                eu_vat_id: billingForm.getFieldValue('vat'),
                first_name: billingForm.getFieldValue('firstName'),
                last_name: billingForm.getFieldValue('lastName'),
            }),
        };

        await BillingService.createUserSubscription(data);
    };

    const handleSetupIntent = async (cardToken: any) => {
        try {
            const intent = await BillingService.createBillingIntent(cardToken);

            setIntentResponse(intent);
            notification.open({
                message: 'Add subscription intent',
                description: 'Intent added successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
        } catch (e: any) {
            setIntentResponse(undefined);

            notification.open({
                message: 'Add subscription intent',
                description: `There was an error adding subscription intent. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const handleCreateUser = async (billingForm: any) => {
        try {
            const data = {
                address: new Address({
                    country: billingForm.getFieldValue('country'),
                    state: billingForm.getFieldValue('state'),
                    city: billingForm.getFieldValue('city'),
                    line_1: billingForm.getFieldValue('line1'),
                    line_2: billingForm.getFieldValue('line2'),
                    postal_code: billingForm.getFieldValue('postalCode'),
                }),
                coupon: form.getFieldValue('coupon'),
                userData: new BillingUserData({
                    company_name: billingForm.getFieldValue('companyName'),
                    eu_vat_id: billingForm.getFieldValue('vat'),
                    first_name: billingForm.getFieldValue('firstName'),
                    last_name: billingForm.getFieldValue('lastName'),
                }),
            };

            await BillingService.createBillingUser(data);

            setSubscriptionUser(true);
            notification.open({
                message: 'Add billing user',
                description: 'User added successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
        } catch (e: any) {
            setSubscriptionUser(false);

            notification.open({
                message: 'Add billing user',
                description: `There was an error adding billing user. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    useEffect(() => {
        if (isBackupRestore && deploymentOptions) {
            setStep('createDatastore');
            backupRestoreWizardInitialStep();
        }
    }, [isBackupRestore, deploymentOptions]);

    const backupRestoreWizardInitialStep = () => {
        if (restoreDatastore && deploymentOptions) {
            const selectedDBVendor = deploymentOptions.getDatabaseVendorByCode(
                restoreDatastore.databaseVendor
            );
            setSelectedTech(selectedDBVendor);
            setDatabaseVendorType(restoreDatastore.databaseVendorType);
            setNumberOfNodes(restoreDatastore.numberOfNodes);

            form.setFieldsValue({
                deploymentName: restoreDatastore.clusterName,
                databaseVendorType: restoreDatastore.databaseVendorType,
                databaseVersion: restoreDatastore.version,
                numberOfNodes: restoreDatastore.numberOfNodes,
                tags: Array.from(new Set(restoreDatastore.tags)),
                databaseVendor: restoreDatastore.databaseVendor,
            });
        }
    };

    return (
        <div className={styles.AddService} data-testid={testId}>
            <Spin spinning={loading} size="large" style={{ maxHeight: '100%' }}>
                <WizardConfiguration
                    activeStep={step}
                    steps={[
                        {
                            step: 'databaseVendor',
                            className: 'ServiceWizard-select-type',
                            content: (
                                <AddServiceDatabaseVendorStep
                                    selectedTechType={selectedTechType}
                                    selectedTech={selectedTech}
                                    handleTechTypeSelect={handleTechTypeSelect}
                                    handleTechTypeContinueClick={
                                        handleTechTypeContinueClick
                                    }
                                    handleBackClick={handleBackClick}
                                    actionType={actionType}
                                    dbVendors={dbVendors}
                                    dbVersion={version}
                                />
                            ),
                        },
                        {
                            step: 'createDatastore',
                            content: (
                                <AddServiceCreateDatastoreStep
                                    form={form}
                                    selectedTech={selectedTech}
                                    databaseVendorType={databaseVendorType}
                                    handleDeselect={handleDeselect}
                                    handleTagChange={handleTagChange}
                                    handleVendorTypeChange={
                                        handleVendorTypeChange
                                    }
                                    selectedCloudProvider={
                                        selectedCloudProvider
                                    }
                                    deploymentOptions={deploymentOptions}
                                    handleCloudProviderChange={
                                        handleCloudProviderChange
                                    }
                                    cloudProvider={cloudProvider}
                                    region={region}
                                    regions={regions}
                                    handleRegionChange={handleRegionChange}
                                    handleInstanceSizeChange={
                                        handleInstanceSizeChange
                                    }
                                    user={user}
                                    handleVolumeTypeChange={
                                        handleVolumeTypeChange
                                    }
                                    handleNetworkChange={handleNetworkChange}
                                    network={network}
                                    setNetwork={setNetwork}
                                    setStep={setStep}
                                    volumeSize={volumeSize}
                                    volumeType={volumeType}
                                    volumeIops={volumeIops}
                                    handleNetworkTypeChange={
                                        handleNetworkTypeChange
                                    }
                                    networkType={networkType}
                                    numberOfNodes={numberOfNodes}
                                    handleNumberOfNodesChange={
                                        handleNumberOfNodesChange
                                    }
                                    handleVpcChange={handleVpcChange}
                                    vpcs={vpcs}
                                    vpc={vpc}
                                    availabilityZones={availabilityZones}
                                    zones={zones}
                                    handleAvailabilityZoneChange={
                                        handleAvailabilityZoneChange
                                    }
                                    handleOnValuesChange={handleOnValuesChange}
                                    handleVolumeIopsChange={
                                        handleVolumeIopsChange
                                    }
                                    logo={
                                        selectedTech &&
                                        getLogo(selectedTech, false)
                                    }
                                    providerLogo={
                                        cloudProvider &&
                                        getCloudProviderLogo(cloudProvider)
                                    }
                                    createUserSubscription={
                                        createUserSubscription
                                    }
                                    enteredVolumeSize={enteredVolumeSize}
                                    setEnteredVolumeSize={setEnteredVolumeSize}
                                    setEnteredIOPS={setEnteredIOPS}
                                    monthlyCost={monthlyCost}
                                    version={version}
                                    subscriptionUser={subscriptionUser}
                                    handleCreateUser={handleCreateUser}
                                    handleSetupIntent={handleSetupIntent}
                                    intentResponse={intentResponse}
                                    setSelectedTechType={setSelectedTechType}
                                    setSelectedTech={setSelectedTech}
                                    setVersion={setVersion}
                                    showCloudProviderStep={
                                        showCloudProviderStep
                                    }
                                    saveEmailNotifications={
                                        saveEmailNotifications
                                    }
                                    notificationEmails={notificationEmails}
                                    instance={instance}
                                />
                            ),
                        },
                        {
                            step: 'createVPC',
                            content: (
                                <AddServiceCreateVPCStep
                                    form={vpcWizardForm}
                                    doApiRequest={doApiRequest}
                                    validateForm={validateForm}
                                    providerLogo={getCloudProviderLogo}
                                    createUserSubscription={
                                        createUserSubscription
                                    }
                                    vpcContext={vpcContext}
                                    subscriptionUser={subscriptionUser}
                                    handleCreateUser={handleCreateUser}
                                    handleSetupIntent={handleSetupIntent}
                                    intentResponse={intentResponse}
                                />
                            ),
                        },
                    ]}
                />
            </Spin>

            <Modal
                title={'Create a VPC'}
                open={vpcModal}
                data-testid={`${testId}Modal`}
                onCancel={handleCancel}
                width={350}
                footer={
                    <Row justify="space-between">
                        <Col span={5}>
                            <Text type="danger">*</Text>Required
                        </Col>
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={handleCancel}
                            >
                                Back
                            </Button>

                            <Button
                                onClick={onSubmitButtonClick}
                                data-testid={`${testId}SubmitButton`}
                                type="primary"
                            >
                                Create
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    layout="vertical"
                    form={vpcForm}
                    scrollToFirstError={true}
                >
                    <AppForm
                        fields={fields.step1}
                        onPressEnter={onSubmitButtonClick}
                        errorFields={errorFields}
                    />
                </Form>
            </Modal>
        </div>
    );
}
