import { Col, Form, Row, Typography } from 'antd';
import { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import { hideCloudProvider } from '../../../core/CcxEnv';
import CloudProvider from '../../../types/CloudProvider';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import styles from './WizardFormConfigurationStep2.module.less';
import AppRadioGroupGrid, {
    AppRadioGridButton,
} from '../../ccx/common/Form/AppRadioGroupGrid';
import { useAppSelector } from '../../../redux/hooks';

interface Props extends CcxComponentProps {
    handleCloudProviderChange: Function;
    cloudProviders?: CloudProvider[];
    regions?: CloudProviderRegion[];
    handleRegionChange: Function;
    form?: any;
    selectedCloudProvider?: CloudProvider;
}

export function WizardFormConfigurationStep2({
    testId = 'WizardFormConfigurationStep2',
    handleCloudProviderChange,
    cloudProviders,
    regions,
    handleRegionChange,
    form,
    selectedCloudProvider,
}: Props): ReactElement {
    const { Text } = Typography;
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );

    useEffect(() => {
        if (isBackupRestore && deploymentOptions && restoreDatastore) {
            const { cloudProvider, region } = form.getFieldsValue();
            if (cloudProvider && region) {
                return;
            }
            const cloudProviderDetails = deploymentOptions?.getCloudProvider(
                restoreDatastore?.cloudProvider
            );
            const cloudProviderRegionDetails =
                deploymentOptions?.getCloudRegionDetails(
                    restoreDatastore?.cloudProvider,
                    restoreDatastore.cloudRegion
                );
            form.setFieldsValue({
                cloudProvider: cloudProviderDetails,
                region: cloudProviderRegionDetails,
            });
            handleCloudProviderChange({
                target: { value: cloudProviderDetails },
            });
            handleRegionChange({
                target: { value: cloudProviderRegionDetails },
            });
        }
    }, [isBackupRestore, deploymentOptions]);

    useEffect(() => {
        if (cloudProviders && cloudProviders.length === 1) {
            form.setFieldsValue({
                cloudProvider: cloudProviders[0],
            });
            handleCloudProviderChange({ target: { value: cloudProviders[0] } });
        }
    }, [cloudProviders]);

    useEffect(() => {
        if (regions && regions.length === 1) {
            form.setFieldsValue({
                region: regions[0],
            });
            handleRegionChange({ target: { value: regions[0] } });
        }
    }, [regions]);

    return (
        <div data-testid={testId}>
            {!hideCloudProvider && (
                <>
                    <p>
                        <strong>Cloud service provider</strong>
                    </p>

                    <Form.Item
                        name="cloudProvider"
                        label=""
                        rules={[
                            {
                                required: true,
                                message: 'Select your preferred cloud provider',
                            },
                        ]}
                    >
                        <AppRadioGroupGrid
                            data-testid={`${testId}CloudProviderRadio`}
                            onChange={handleCloudProviderChange as any}
                            items={
                                cloudProviders?.map(
                                    (p: CloudProvider, i: number) => {
                                        return (
                                            <AppRadioGridButton
                                                data-testid={`${testId}CloudProviderRadio${p.code}`}
                                                value={p}
                                                key={`${i}-${p.code}`}
                                                className={
                                                    selectedCloudProvider?.code ===
                                                    p.code
                                                        ? styles.SelectedCloudProvider
                                                        : ''
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.ProviderRadioButton
                                                    }
                                                >
                                                    <img
                                                        src={p.logo}
                                                        alt={p.code}
                                                        className={
                                                            styles.ProviderLogo
                                                        }
                                                    />
                                                    <small>{p.name}</small>
                                                </div>
                                            </AppRadioGridButton>
                                        );
                                    }
                                ) || []
                            }
                        />
                    </Form.Item>
                </>
            )}

            {regions?.length && (
                <>
                    <p>
                        <strong>Regions</strong>
                    </p>

                    <Form.Item
                        name="region"
                        label=""
                        rules={[
                            {
                                required: true,
                                message: 'Select your preferred region',
                            },
                        ]}
                    >
                        <AppRadioGroupGrid
                            data-testid={`${testId}RegionRadio`}
                            onChange={handleRegionChange as any}
                            items={
                                regions.map(
                                    (p: CloudProviderRegion, i: number) => {
                                        return (
                                            <AppRadioGridButton
                                                data-testid={`${testId}RegionsRadio${p.code}`}
                                                value={p}
                                                size={'large'}
                                                key={`${i}-${p.displayCode}`}
                                            >
                                                <Row align="middle">
                                                    <Col span={4}>
                                                        <AppFlagIcon
                                                            code={p.countryCode}
                                                        />
                                                    </Col>
                                                    <Col span={20}>
                                                        <div>
                                                            <div
                                                                className={
                                                                    styles.WizardFormConfigurationStep2RegionLabel
                                                                }
                                                            >
                                                                <small>
                                                                    {
                                                                        p.displayCode
                                                                    }
                                                                </small>
                                                                <Text type="secondary">
                                                                    <small>
                                                                        {p.city}
                                                                    </small>
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AppRadioGridButton>
                                        );
                                    }
                                ) || []
                            }
                        />
                    </Form.Item>
                </>
            )}
        </div>
    );
}
